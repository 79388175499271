@import '@fontsource/dotgothic16';

.hero-footer {
    background-image: url("../../../assets/images/footer.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    height: 346px;
}

.footer{    
    height: 550px;
    background: #9E62DE;
    padding: 5em 10em 8rem;
    .text{        
        font-family: 'DotGothic16';
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        color: #FFFFFF;
        margin-bottom: 10px;
        cursor: pointer;
        &:hover {
            font-weight: bold;
        }
    }
    .subtext{        
        font-family: 'DotGothic16';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 23px;
        text-align: right;
        color: #FFFFFF;
        cursor: pointer;
        &:hover {
            font-weight: bold;
        }
    }
}

.support{
    display: flex;
    margin-top: 2em;
    .client{
        height: 43px;
        margin-right: 2rem;
        cursor: pointer;
    }
}

.copyright{
    position: absolute;
    bottom: 2em;
    right: 10em;
    color: #FFFFFF;
}

@media only screen and (min-width: 605px) and (max-width: 1100px) {    
    .support{
        margin: 2em 0;
    }
    .copyright{
        bottom: 1em;
    }
}

@media only screen and (max-width: 600px) {
    .hero-footer {
        background-image: url("../../../assets/images/footer-xs.png");
        height: 200px;
    }
    .footer{        
        height: 930px;
        padding: 0;
        .wrapper{
            padding: 2em 8em 0;
        }
        .text, .subtext{
            text-align: center;
            margin-bottom: 10px;
        }
        .text{            
            margin-bottom: 1em;
        }
        img{            
            // width: 120px;
            margin: 0 auto 2rem;
        }
    }
    .footer-space{
        margin-bottom: 2rem;
    }
    .support{
        margin: 2em 0;
        display: grid;
        .client{
            margin: 1em auto;
        }
        .client:nth-child(2), .client:nth-child(3){
            height: 30px;
        }
    }
    .copyright{
        position: relative;
        text-align: center;
        padding: 0;
        margin: 0 50px;
        right: unset;
        bottom: unset;
    }
}